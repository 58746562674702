/*--------------------------------------------------------------------------------------------
*
*	Vars
*
*--------------------------------------------------------------------------------------------*/
/* colors */
/* acf-field */
/* responsive */
/*--------------------------------------------------------------------------------------------
*
*  Mixins
*
*--------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------
*
*  Global
*
*--------------------------------------------------------------------------------------------*/
/* Horizontal List */
.acf-hl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  position: relative;
}

.acf-hl > li {
  float: left;
  display: block;
  margin: 0;
  padding: 0;
}

.acf-hl > li.acf-fr {
  float: right;
}

/* Horizontal List: Clearfix */
.acf-hl:before, .acf-hl:after,
.acf-bl:before, .acf-bl:after,
.acf-cf:before, .acf-cf:after {
  content: "";
  display: block;
  line-height: 0;
}

.acf-hl:after,
.acf-bl:after,
.acf-cf:after {
  clear: both;
}

/* Block List */
.acf-bl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  position: relative;
}

.acf-bl > li {
  display: block;
  margin: 0;
  padding: 0;
  float: none;
}

/* Visibility */
.acf-hidden {
  display: none !important;
}

.acf-empty {
  display: table-cell !important;
}
.acf-empty * {
  display: none !important;
}

/* Float */
.acf-fl {
  float: left;
}

.acf-fr {
  float: right;
}

.acf-fn {
  float: none;
}

/* Align */
.acf-al {
  text-align: left;
}

.acf-ar {
  text-align: right;
}

.acf-ac {
  text-align: center;
}

/* loading */
.acf-loading,
.acf-spinner {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: text-top;
  background: transparent url(../../images/spinner.gif) no-repeat 50% 50%;
}

/* spinner */
.acf-spinner {
  display: none;
}

.acf-spinner.is-active {
  display: inline-block;
}

/* WP < 4.2 */
.spinner.is-active {
  display: inline-block;
}

/* required */
.acf-required {
  color: #f00;
}

/* show on hover */
.acf-soh .acf-soh-target {
  -webkit-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  -moz-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  -o-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  visibility: hidden;
  opacity: 0;
}

.acf-soh:hover .acf-soh-target {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}

/* show if value */
.show-if-value {
  display: none;
}

.hide-if-value {
  display: block;
}

.has-value .show-if-value {
  display: block;
}

.has-value .hide-if-value {
  display: none;
}

/* select2 WP animation fix */
.select2-search-choice-close {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

/*---------------------------------------------------------------------------------------------
*
*  tooltip
*
*---------------------------------------------------------------------------------------------*/
/* tooltip */
.acf-tooltip {
  background: #2F353E;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  font-size: 12px;
  line-height: 1.4em;
  z-index: 900000;
  /* tip */
  /* positions */
}
.acf-tooltip:before {
  border: solid;
  border-color: transparent;
  border-width: 6px;
  content: "";
  position: absolute;
}
.acf-tooltip.top {
  margin-top: -8px;
}
.acf-tooltip.top:before {
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top-color: #2F353E;
  border-bottom-width: 0;
}
.acf-tooltip.right {
  margin-left: 8px;
}
.acf-tooltip.right:before {
  top: 50%;
  margin-top: -6px;
  right: 100%;
  border-right-color: #2F353E;
  border-left-width: 0;
}
.acf-tooltip.bottom {
  margin-top: 8px;
}
.acf-tooltip.bottom:before {
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-bottom-color: #2F353E;
  border-top-width: 0;
}
.acf-tooltip.left {
  margin-left: -8px;
}
.acf-tooltip.left:before {
  top: 50%;
  margin-top: -6px;
  left: 100%;
  border-left-color: #2F353E;
  border-right-width: 0;
}
.acf-tooltip .acf-overlay {
  z-index: -1;
}

/* confirm */
.acf-tooltip.-confirm {
  z-index: 900001;
}
.acf-tooltip.-confirm a {
  text-decoration: none;
  color: #9ea3a8;
}
.acf-tooltip.-confirm a:hover {
  text-decoration: underline;
}
.acf-tooltip.-confirm a[data-event=confirm] {
  color: #F55E4F;
}

.acf-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
}

.acf-tooltip-target {
  position: relative;
  z-index: 900002;
}

/*---------------------------------------------------------------------------------------------
*
*  loading
*
*---------------------------------------------------------------------------------------------*/
.acf-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
  z-index: 99;
  background: rgba(249, 249, 249, 0.5);
}
.acf-loading-overlay i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*--------------------------------------------------------------------------------------------
*
*	acf-icon
*
*--------------------------------------------------------------------------------------------*/
.acf-icon {
  display: inline-block;
  height: 28px;
  width: 28px;
  border: transparent solid 1px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  box-sizing: border-box;
}
.acf-icon:before {
  font-family: dashicons;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  text-align: center;
}

.acf-icon.-plus:before {
  content: "\f543";
}

.acf-icon.-minus:before {
  content: "\f460";
}

.acf-icon.-cancel:before {
  content: "\f335";
}

.acf-icon.-pencil:before {
  content: "\f464";
}

.acf-icon.-location:before {
  content: "\f230";
}

.acf-icon.-up:before {
  content: "\f343";
  margin-top: -0.1em;
}

.acf-icon.-down:before {
  content: "\f347";
  margin-top: 0.1em;
}

.acf-icon.-left:before {
  content: "\f341";
  margin-left: -0.1em;
}

.acf-icon.-right:before {
  content: "\f345";
  margin-left: 0.1em;
}

.acf-icon.-sync:before {
  content: "\f463";
}

.acf-icon.-globe:before {
  content: "\f319";
  margin-top: 0.1em;
  margin-left: 0.1em;
}

.acf-icon.-picture:before {
  content: "\f128";
}

.acf-icon.-check:before {
  content: "\f147";
  margin-left: -0.1em;
}

.acf-icon.-dot-3:before {
  content: "\f533";
  margin-top: -0.1em;
}

.acf-icon.-arrow-combo:before {
  content: "\f156";
}

.acf-icon.-arrow-up:before {
  content: "\f142";
  margin-left: -0.1em;
}

.acf-icon.-arrow-down:before {
  content: "\f140";
  margin-left: -0.1em;
}

.acf-icon.-search:before {
  content: "\f179";
}

.acf-icon.-link-ext:before {
  content: "\f504";
}

.acf-icon.-duplicate {
  position: relative;
}
.acf-icon.-duplicate:before, .acf-icon.-duplicate:after {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 46%;
  height: 46%;
  position: absolute;
  top: 33%;
  left: 23%;
}
.acf-icon.-duplicate:before {
  margin: -1px 0 0 1px;
  box-shadow: 2px -2px 0px 0px currentColor;
}
.acf-icon.-duplicate:after {
  border: solid 2px currentColor;
}

.acf-icon.-collapse:before {
  content: "\f142";
  margin-left: -0.1em;
}

.-collapsed .acf-icon.-collapse:before {
  content: "\f140";
  margin-left: -0.1em;
}

span.acf-icon {
  color: #555d66;
  border-color: #b5bcc2;
  background-color: #fff;
}

a.acf-icon {
  color: #555d66;
  border-color: #b5bcc2;
  background-color: #fff;
  position: relative;
  transition: none;
  cursor: pointer;
}
a.acf-icon:hover {
  background: #f3f5f6;
  border-color: #0071a1;
  color: #0071a1;
}
a.acf-icon.-minus:hover, a.acf-icon.-cancel:hover {
  background: #f7efef;
  border-color: #a10000;
  color: #dc3232;
}
a.acf-icon:active, a.acf-icon:focus {
  outline: none;
  box-shadow: none;
}

.acf-icon.-clear {
  border-color: transparent;
  background: transparent;
  color: #444;
}

.acf-icon.light {
  border-color: transparent;
  background: #F5F5F5;
  color: #23282d;
}

.acf-icon.dark {
  border-color: transparent !important;
  background: #23282D;
  color: #eee;
}

a.acf-icon.dark:hover {
  background: #191E23;
  color: #00b9eb;
}
a.acf-icon.dark.-minus:hover, a.acf-icon.dark.-cancel:hover {
  color: #D54E21;
}

.acf-icon.grey {
  border-color: transparent !important;
  background: #b4b9be;
  color: #fff !important;
}
.acf-icon.grey:hover {
  background: #00A0D2;
  color: #fff;
}
.acf-icon.grey.-minus:hover, .acf-icon.grey.-cancel:hover {
  background: #32373C;
}

.acf-icon.small,
.acf-icon.-small {
  width: 20px;
  height: 20px;
  line-height: 14px;
  font-size: 14px;
}
.acf-icon.small.-duplicate:before, .acf-icon.small.-duplicate:after,
.acf-icon.-small.-duplicate:before,
.acf-icon.-small.-duplicate:after {
  opacity: 0.8;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-box
*
*--------------------------------------------------------------------------------------------*/
.acf-box {
  background: #FFFFFF;
  border: 1px solid #ccd0d4;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  /* title */
  /* footer */
}
.acf-box .title {
  border-bottom: 1px solid #ccd0d4;
  margin: 0;
  padding: 15px;
}
.acf-box .title h3 {
  font-size: 14px;
  line-height: 1em;
  margin: 0;
  padding: 0;
}
.acf-box .inner {
  padding: 15px;
}
.acf-box h2 {
  color: #333333;
  font-size: 26px;
  line-height: 1.25em;
  margin: 0.25em 0 0.75em;
  padding: 0;
}
.acf-box h3 {
  margin: 1.5em 0 0;
}
.acf-box p {
  margin-top: 0.5em;
}
.acf-box a {
  text-decoration: none;
}
.acf-box i.dashicons-external {
  margin-top: -1px;
}
.acf-box .footer {
  border-top: 1px solid #ccd0d4;
  padding: 12px;
  font-size: 13px;
  line-height: 1.5;
}
.acf-box .footer p {
  margin: 0;
}
.acf-admin-3-8 .acf-box {
  border-color: #E5E5E5;
}
.acf-admin-3-8 .acf-box .title,
.acf-admin-3-8 .acf-box .footer {
  border-color: #E5E5E5;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-notice
*
*--------------------------------------------------------------------------------------------*/
.acf-notice {
  position: relative;
  display: block;
  color: #fff;
  margin: 5px 0 15px;
  padding: 3px 12px;
  background: #2a9bd9;
  border-left: #1f7db1 solid 3px;
}
.acf-notice p {
  font-size: 13px;
  line-height: 1.5;
  margin: 0.5em 0;
  text-shadow: none;
  color: inherit;
}
.acf-notice .acf-notice-dismiss {
  position: absolute;
  top: 9px;
  right: 12px;
  background: transparent !important;
  color: inherit !important;
  border-color: #fff !important;
  opacity: 0.75;
}
.acf-notice .acf-notice-dismiss:hover {
  opacity: 1;
}
.acf-notice.-dismiss {
  padding-right: 40px;
}
.acf-notice.-error {
  background: #d94f4f;
  border-color: #c92c2c;
}
.acf-notice.-success {
  background: #49ad52;
  border-color: #3a8941;
}
.acf-notice.-warning {
  background: #fd8d3b;
  border-color: #fc7009;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-table
*
*--------------------------------------------------------------------------------------------*/
.acf-table {
  border: #ccd0d4 solid 1px;
  background: #fff;
  border-spacing: 0;
  border-radius: 0;
  table-layout: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  clear: both;
  box-sizing: content-box;
  /* defaults */
  /* thead */
  /* tbody */
  /* -clear */
}
.acf-table > tbody > tr > th, .acf-table > tbody > tr > td,
.acf-table > thead > tr > th,
.acf-table > thead > tr > td {
  padding: 8px;
  vertical-align: top;
  background: #fff;
  text-align: left;
  border-style: solid;
  font-weight: normal;
}
.acf-table > tbody > tr > th,
.acf-table > thead > tr > th {
  position: relative;
  color: #333333;
}
.acf-table > thead > tr > th {
  border-color: #d5d9dd;
  border-width: 0 0 1px 1px;
}
.acf-table > thead > tr > th:first-child {
  border-left-width: 0;
}
.acf-table > tbody > tr {
  z-index: 1;
}
.acf-table > tbody > tr > td {
  border-color: #eeeeee;
  border-width: 1px 0 0 1px;
}
.acf-table > tbody > tr > td:first-child {
  border-left-width: 0;
}
.acf-table > tbody > tr:first-child > td {
  border-top-width: 0;
}
.acf-table.-clear {
  border: 0 none;
}
.acf-table.-clear > tbody > tr > td, .acf-table.-clear > tbody > tr > th,
.acf-table.-clear > thead > tr > td,
.acf-table.-clear > thead > tr > th {
  border: 0 none;
  padding: 4px;
}

/* remove tr */
.acf-remove-element {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  transform: translate(50px, 0);
  opacity: 0;
}

/* fade-up */
.acf-fade-up {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  transform: translate(0, -10px);
  opacity: 0;
}

/*---------------------------------------------------------------------------------------------
*
*  Fake table
*
*---------------------------------------------------------------------------------------------*/
.acf-thead,
.acf-tbody,
.acf-tfoot {
  width: 100%;
  padding: 0;
  margin: 0;
}
.acf-thead > li,
.acf-tbody > li,
.acf-tfoot > li {
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 14px;
}

.acf-thead {
  border-bottom: #ccd0d4 solid 1px;
  color: #23282d;
}
.acf-thead > li {
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;
}
.acf-admin-3-8 .acf-thead {
  border-color: #dfdfdf;
}

.acf-tfoot {
  background: #f5f5f5;
  border-top: #d5d9dd solid 1px;
}

/*--------------------------------------------------------------------------------------------
*
*	Settings
*
*--------------------------------------------------------------------------------------------*/
.acf-settings-wrap #poststuff {
  padding-top: 15px;
}
.acf-settings-wrap .acf-box {
  margin: 20px 0;
}
.acf-settings-wrap table {
  margin: 0;
}
.acf-settings-wrap table .button {
  vertical-align: middle;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-popup
*
*--------------------------------------------------------------------------------------------*/
#acf-popup {
  position: fixed;
  z-index: 900000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
#acf-popup .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.25);
}
#acf-popup:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
#acf-popup .acf-popup-box {
  display: inline-block;
  vertical-align: middle;
  z-index: 1;
  min-width: 300px;
  min-height: 160px;
  border-color: #aaaaaa;
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.25);
  text-align: left;
}
html[dir=rtl] #acf-popup .acf-popup-box {
  text-align: right;
}
#acf-popup .acf-popup-box .title {
  min-height: 15px;
  line-height: 15px;
}
#acf-popup .acf-popup-box .title .acf-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
html[dir=rtl] #acf-popup .acf-popup-box .title .acf-icon {
  right: auto;
  left: 10px;
}
#acf-popup .acf-popup-box .inner {
  min-height: 50px;
  padding: 0;
  margin: 15px;
}
#acf-popup .acf-popup-box .loading {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  display: none;
}
#acf-popup .acf-popup-box .loading i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.acf-submit {
  margin-bottom: 0;
  line-height: 28px;
}
.acf-submit span {
  float: right;
  color: #999;
}
.acf-submit span.-error {
  color: #dd4232;
}
.acf-submit .button {
  margin-right: 5px;
}

/*--------------------------------------------------------------------------------------------
*
*	upgrade notice
*
*--------------------------------------------------------------------------------------------*/
#acf-upgrade-notice {
  position: relative;
  background: #fff;
  border-left: 4px solid #00a0d2;
  padding: 20px;
}
#acf-upgrade-notice:after {
  display: block;
  clear: both;
  content: "";
}
#acf-upgrade-notice .col-content {
  float: left;
  width: 55%;
  padding-left: 90px;
}
#acf-upgrade-notice .col-actions {
  float: right;
  text-align: center;
  padding: 10px;
}
#acf-upgrade-notice img {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0 0 0 -90px;
}
#acf-upgrade-notice h2 {
  font-size: 16px;
  margin: 2px 0 6.5px;
}
#acf-upgrade-notice p {
  padding: 0;
  margin: 0;
}
#acf-upgrade-notice .button:before {
  margin-top: 11px;
}
@media screen and (max-width: 640px) {
  #acf-upgrade-notice .col-content,
#acf-upgrade-notice .col-actions {
    float: none;
    padding-left: 90px;
    width: auto;
    text-align: left;
  }
}

/*--------------------------------------------------------------------------------------------
*
*	Welcome
*
*--------------------------------------------------------------------------------------------*/
.acf-wrap h1 {
  margin-top: 0;
  padding-top: 20px;
}
.acf-wrap .about-text {
  margin-top: 0.5em;
  min-height: 50px;
}
.acf-wrap .about-headline-callout {
  font-size: 2.4em;
  font-weight: 300;
  line-height: 1.3;
  margin: 1.1em 0 0.2em;
  text-align: center;
}
.acf-wrap .feature-section {
  padding: 40px 0;
}
.acf-wrap .feature-section h2 {
  margin-top: 20px;
}
.acf-wrap .changelog {
  list-style: disc;
  padding-left: 15px;
}
.acf-wrap .changelog li {
  margin: 0 0 0.75em;
}
.acf-wrap .acf-three-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.acf-wrap .acf-three-col > div {
  flex: 1;
  align-self: flex-start;
  min-width: 31%;
  max-width: 31%;
}
@media screen and (max-width: 880px) {
  .acf-wrap .acf-three-col > div {
    min-width: 48%;
  }
}
@media screen and (max-width: 640px) {
  .acf-wrap .acf-three-col > div {
    min-width: 100%;
  }
}
.acf-wrap .acf-three-col h3 .badge {
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  background: #fc9700;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  padding: 2px 5px;
}
.acf-wrap .acf-three-col img + h3 {
  margin-top: 0.5em;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-hl cols
*
*--------------------------------------------------------------------------------------------*/
.acf-hl[data-cols] {
  margin-left: -10px;
  margin-right: -10px;
}
.acf-hl[data-cols] > li {
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* sizes */
.acf-hl[data-cols="2"] > li {
  width: 50%;
}

.acf-hl[data-cols="3"] > li {
  width: 33.333%;
}

.acf-hl[data-cols="4"] > li {
  width: 25%;
}

/* mobile */
@media screen and (max-width: 640px) {
  .acf-hl[data-cols] {
    margin-left: 0;
    margin-right: 0;
    margin-top: -10px;
  }
  .acf-hl[data-cols] > li {
    width: 100% !important;
    padding: 10px 0 0;
  }
}
/*--------------------------------------------------------------------------------------------
*
*	misc
*
*--------------------------------------------------------------------------------------------*/
.acf-actions {
  text-align: right;
  z-index: 1;
  /* hover */
  /* rtl */
}
.acf-actions.-hover {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  padding: 5px;
}
html[dir=rtl] .acf-actions.-hover {
  right: auto;
  left: 0;
}

/* ul compatibility */
ul.acf-actions li {
  float: right;
  margin-left: 4px;
}

/*--------------------------------------------------------------------------------------------
*
*	RTL
*
*--------------------------------------------------------------------------------------------*/
html[dir=rtl] .acf-fl {
  float: right;
}

html[dir=rtl] .acf-fr {
  float: left;
}

html[dir=rtl] .acf-hl > li {
  float: right;
}

html[dir=rtl] .acf-hl > li.acf-fr {
  float: left;
}

html[dir=rtl] .acf-icon.logo {
  left: 0;
  right: auto;
}

html[dir=rtl] .acf-table thead th {
  text-align: right;
  border-right-width: 1px;
  border-left-width: 0px;
}

html[dir=rtl] .acf-table > tbody > tr > td {
  text-align: right;
  border-right-width: 1px;
  border-left-width: 0px;
}

html[dir=rtl] .acf-table > thead > tr > th:first-child,
html[dir=rtl] .acf-table > tbody > tr > td:first-child {
  border-right-width: 0;
}

html[dir=rtl] .acf-table > tbody > tr > td.order + td {
  border-right-color: #e1e1e1;
}

/*---------------------------------------------------------------------------------------------
*
*  acf-postbox-columns
*
*---------------------------------------------------------------------------------------------*/
.acf-postbox-columns {
  position: relative;
  margin-top: -11px;
  margin-bottom: -12px;
  margin-left: -12px;
  margin-right: 268px;
}
.acf-postbox-columns:after {
  display: block;
  clear: both;
  content: "";
}
.acf-postbox-columns .acf-postbox-main,
.acf-postbox-columns .acf-postbox-side {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px 12px;
}
.acf-postbox-columns .acf-postbox-main {
  float: left;
  width: 100%;
}
.acf-postbox-columns .acf-postbox-side {
  float: right;
  width: 280px;
  margin-right: -280px;
}
.acf-postbox-columns .acf-postbox-side:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: 0;
  background: #d5d9dd;
}
.acf-admin-3-8 .acf-postbox-columns .acf-postbox-side:before {
  background: #dfdfdf;
}

/* mobile */
@media only screen and (max-width: 850px) {
  .acf-postbox-columns {
    margin: 0;
  }
  .acf-postbox-columns .acf-postbox-main,
.acf-postbox-columns .acf-postbox-side {
    float: none;
    width: auto;
    margin: 0;
    padding: 0;
  }
  .acf-postbox-columns .acf-postbox-side {
    margin-top: 1em;
  }
  .acf-postbox-columns .acf-postbox-side:before {
    display: none;
  }
}
/*---------------------------------------------------------------------------------------------
*
*  acf-panel
*
*---------------------------------------------------------------------------------------------*/
.acf-panel {
  margin-top: -1px;
  border-top: 1px solid #d5d9dd;
  border-bottom: 1px solid #d5d9dd;
  /* open */
  /* inside postbox */
  /* fields */
}
.acf-panel .acf-panel-title {
  margin: 0;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: inherit;
}
.acf-panel .acf-panel-title i {
  float: right;
}
.acf-panel .acf-panel-inside {
  margin: 0;
  padding: 0 12px 12px;
  display: none;
}
.acf-panel.-open .acf-panel-inside {
  display: block;
}
.postbox .acf-panel {
  margin-left: -12px;
  margin-right: -12px;
}
.acf-panel .acf-field {
  margin: 20px 0 0;
}
.acf-panel .acf-field .acf-label label {
  color: #555d66;
  font-weight: normal;
}
.acf-panel .acf-field:first-child {
  margin-top: 0;
}
.acf-admin-3-8 .acf-panel {
  border-color: #dfdfdf;
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Tools
*
*---------------------------------------------------------------------------------------------*/
#acf-admin-tools .notice {
  margin-top: 10px;
}

.acf-meta-box-wrap {
  margin-top: 10px;
  /* acf-fields */
}
.acf-meta-box-wrap .postbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.acf-meta-box-wrap .postbox .inside {
  margin-bottom: 0;
}
.acf-meta-box-wrap .postbox .hndle {
  font-size: 14px;
  padding: 8px 12px;
  margin: 0;
  line-height: 1.4;
  position: relative;
  z-index: 1;
  cursor: default;
}
.acf-meta-box-wrap .postbox .handlediv,
.acf-meta-box-wrap .postbox .handle-order-higher,
.acf-meta-box-wrap .postbox .handle-order-lower {
  display: none;
}
.acf-meta-box-wrap .acf-fields {
  border: #ebebeb solid 1px;
  background: #fafafa;
  border-radius: 3px;
}

/* grid */
.acf-meta-box-wrap.-grid {
  margin-left: 8px;
  margin-right: 8px;
}
.acf-meta-box-wrap.-grid .postbox {
  float: left;
  clear: left;
  width: 50%;
  margin: 0 0 16px;
}
.acf-meta-box-wrap.-grid .postbox:nth-child(odd) {
  margin-left: -8px;
}
.acf-meta-box-wrap.-grid .postbox:nth-child(even) {
  float: right;
  clear: right;
  margin-right: -8px;
}

/* mobile */
@media only screen and (max-width: 850px) {
  .acf-meta-box-wrap.-grid {
    margin-left: 0;
    margin-right: 0;
  }
  .acf-meta-box-wrap.-grid .postbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
/* export tool */
#acf-admin-tool-export {
  /* panel: selection */
}
#acf-admin-tool-export p {
  max-width: 800px;
}
#acf-admin-tool-export ul {
  column-width: 200px;
}
#acf-admin-tool-export .acf-postbox-side .button {
  margin: 0;
  width: 100%;
}
#acf-admin-tool-export textarea {
  display: block;
  width: 100%;
  min-height: 500px;
  background: #fafafa;
  box-shadow: none;
  padding: 7px;
  border-radius: 3px;
}
#acf-admin-tool-export .acf-panel-selection .acf-label {
  display: none;
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Navigation
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-toolbar {
  background: #fff;
  border-bottom: 1px solid #ccd0d4;
  padding-top: 10px;
}
.acf-admin-toolbar h2 {
  font-size: 14px;
  line-height: 2.5714285714;
  display: inline-block;
  padding: 5px 0;
  margin: 0 10px 0 0;
}
.acf-admin-toolbar h2 i {
  vertical-align: middle;
  color: #babbbc;
}
.acf-admin-toolbar .acf-tab {
  display: inline-block;
  font-size: 14px;
  line-height: 2.5714285714;
  padding: 5px;
  margin: 0 5px;
  text-decoration: none;
  color: inherit;
}
.acf-admin-toolbar .acf-tab.is-active {
  border-bottom: #0071a4 solid 3px;
  padding-bottom: 10px;
}
.acf-admin-toolbar .acf-tab:hover {
  color: #00a0d2;
}
.acf-admin-toolbar .acf-tab:focus {
  box-shadow: none;
}
.acf-admin-toolbar a.btn-upgrade {
  display: inline-flex;
  background: #dee3ff;
  border: 1px solid #c6d3f8;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0 0.75rem;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -6px;
  margin-left: 20px;
  text-decoration: none;
}
.acf-admin-toolbar a.btn-upgrade:hover {
  background: #d1ddff;
  border-color: #b9caf9;
}
.acf-admin-toolbar a.btn-upgrade p {
  font-family: system-ui;
  font-weight: 600;
  letter-spacing: 0.2;
  line-height: 1;
  font-size: 0.625rem;
  text-transform: uppercase;
  color: #425fdd;
  margin-left: 0.65rem;
}
#wpcontent .acf-admin-toolbar {
  margin-left: -20px;
  padding-left: 20px;
}
@media screen and (max-width: 600px) {
  .acf-admin-toolbar {
    display: none;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Field Groups
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-field-groups .tablenav.top {
  display: none;
}
.acf-admin-field-groups .subsubsub {
  margin-bottom: 3px;
}
.acf-admin-field-groups .wp-list-table td, .acf-admin-field-groups .wp-list-table th {
  box-sizing: border-box;
}
.acf-admin-field-groups .wp-list-table tr:hover {
  background: #f7f7f7;
}
@media screen and (min-width: 782px) {
  .acf-admin-field-groups .wp-list-table .column-acf-count {
    width: 10%;
  }
}
.acf-admin-field-groups .wp-list-table .row-actions span.file {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.acf-admin-field-groups .acf-secondary-text {
  color: #a0a5aa;
}

.acf-multi-dashicon {
  position: relative;
  z-index: 1;
}
.acf-multi-dashicon:before, .acf-multi-dashicon:after {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  background: #fff;
  border: #7e8993 solid 1px;
  border-radius: 2px;
  display: block;
}
.acf-multi-dashicon:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: -1;
  background: #e7e7e7;
}

.acf-css-tooltip {
  position: relative;
}
.acf-css-tooltip:before {
  content: attr(aria-label);
  display: none;
  position: absolute;
  z-index: 999;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -8px);
  background: #191e23;
  border-radius: 2px;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  line-height: 1.4em;
  white-space: pre;
}
.acf-css-tooltip:after {
  content: "";
  display: none;
  position: absolute;
  z-index: 998;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 4px);
  border: solid 6px transparent;
  border-top-color: #191e23;
}
.acf-css-tooltip:hover:before, .acf-css-tooltip:hover:after, .acf-css-tooltip:focus:before, .acf-css-tooltip:focus:after {
  display: block;
}

.acf-diff .acf-diff-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding: 14px 16px;
  background: #f3f3f3;
  border-bottom: #dddddd solid 1px;
}
.acf-diff .acf-diff-title strong {
  font-size: 14px;
  display: block;
}
.acf-diff .acf-diff-title .acf-diff-title-left,
.acf-diff .acf-diff-title .acf-diff-title-right {
  width: 50%;
  float: left;
}
.acf-diff .acf-diff-content {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.acf-diff table.diff {
  border-spacing: 0;
}
.acf-diff table.diff col.diffsplit.middle {
  width: 0;
}
.acf-diff table.diff td, .acf-diff table.diff th {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.acf-diff table.diff tr td:nth-child(2) {
  width: auto;
}
.acf-diff table.diff td:nth-child(3) {
  border-left: #dddddd solid 1px;
}
@media screen and (max-width: 600px) {
  .acf-diff .acf-diff-title {
    height: 70px;
  }
  .acf-diff .acf-diff-content {
    top: 100px;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Modal
*
*---------------------------------------------------------------------------------------------*/
.acf-modal {
  position: fixed;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 160000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
  background: #fcfcfc;
}
.acf-modal .acf-modal-title,
.acf-modal .acf-modal-content,
.acf-modal .acf-modal-toolbar {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
}
.acf-modal .acf-modal-title {
  height: 50px;
  top: 0;
  border-bottom: 1px solid #ddd;
}
.acf-modal .acf-modal-title h2 {
  margin: 0;
  padding: 0 16px;
  line-height: 50px;
}
.acf-modal .acf-modal-title .acf-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border: none;
  border-left: 1px solid #ddd;
  background: transparent;
  cursor: pointer;
  color: #666;
}
.acf-modal .acf-modal-title .acf-modal-close:hover {
  color: #00a0d2;
}
.acf-modal .acf-modal-content {
  top: 50px;
  bottom: 60px;
  background: #fff;
  overflow: auto;
  padding: 16px;
}
.acf-modal .acf-modal-feedback {
  position: absolute;
  top: 50%;
  margin: -10px 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.75;
}
.acf-modal .acf-modal-feedback.error {
  opacity: 1;
  color: #b52727;
}
.acf-modal .acf-modal-toolbar {
  height: 60px;
  bottom: 0;
  padding: 15px 16px;
  border-top: 1px solid #ddd;
}
.acf-modal .acf-modal-toolbar .button {
  float: right;
}
@media only screen and (max-width: 640px) {
  .acf-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.acf-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.7;
  z-index: 159900;
}

/*---------------------------------------------------------------------------------------------
*
*  Retina
*
*---------------------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .acf-loading,
.acf-spinner {
    background-image: url(../../images/spinner@2x.gif);
    background-size: 20px 20px;
  }
}